import React, { useEffect } from 'react';

const PREVENTIVE_LOADING_HEIGHT = 1720;
const PAGINATION_STEP = 20;

function useSearchPaginationOnScroll(
  listRef,
  setOffset,
  isScrolledDown,
  setIsScrollDown,
) {
  const handleScroll = async () => {
    if (!listRef.current) return;

    if (
      document.defaultView?.scrollY >=
      listRef.current.offsetHeight - PREVENTIVE_LOADING_HEIGHT
    ) {
      setIsScrollDown(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isScrolledDown) return;

    setOffset((offset) => offset + PAGINATION_STEP);
  }, [isScrolledDown]);
}

export default useSearchPaginationOnScroll;
