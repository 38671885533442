import React from 'react';
import { Spinner } from 'theme-ui';
import SpinnerWrapper from './Loader.styled';

const Loader = ({ isSmall, isFullHeight, isPagination }) => (
  <SpinnerWrapper $isPagination={isPagination} $isFullHeight={isFullHeight}>
    <Spinner color='green' size={isSmall ? 72 : 86} />
  </SpinnerWrapper>
);

Loader.defaultProps = {
  isPagination: false,
  isFullHeight: false,
  isSmall: false,
};

export default Loader;
