import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import { BASIC_CONTAINER_WIDTH } from '../../consts/style-consts';
import { RegularAnchor } from '../../shared-styled/typography/Anchors';
import pxToRem from '../../utils/pixelsToRem';

export const Wrapper = styled(Flex)(
  css({
    width: BASIC_CONTAINER_WIDTH,
    flexDirection: 'column',
  }),
);

export const Container = styled(Flex)(
  css({
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    padding: '32px 0 0 0',
    background: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
  }),
);

export const Label = styled.p(
  css({
    mt: '-24px',
    mb: '24px',
  }),
);

export const SectionTitle = styled.p(
  css({
    pl: '36px',
    pb: '6px',
    fontWeight: '600',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    textTransform: 'uppercase',
    color: 'secondaryGreen',
    borderBottom: '2px solid',
    borderColor: 'searchCategorySeparator',
  }),
);

export const SearchListLink = styled(RegularAnchor)(
  css({
    pl: '36px',
    py: '12px',

    '&:hover': {
      backgroundColor: 'listElementHover',
      borderLeft: '5px solid',
      borderColor: 'secondaryGreen',
      pl: '31px',
    },

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  }),
);

export const PaginationContainer = styled(Flex)(
  css({
    justifyContent: 'space-between',
    margin: '32px 20px 0px 20px',
  }),
);

export const Placeholder = styled('div')(
  css({
    flex: 1,
    padding: '16px 16px 18px 16px',
    borderTop: '2px solid',
    borderColor: 'searchCategorySeparator',
  }),
);

export const StepButton = styled('button')(
  css({
    justifyContent: 'space-between',
    color: 'gray500',
    padding: '16px 16px 18px 16px',
    flex: 1,
    background: 'white',
    borderTop: '2px solid',
    borderColor: 'searchCategorySeparator',
    fontSize: pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',

    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  }),
  ({ $isLeft }) =>
    css({
      justifyContent: $isLeft ? 'flex-start' : 'flex-end',
    }),
);

export const PageButtonsContainer = styled(Flex)(
  css({
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const PageButton = styled('button')(
  css({
    justifyContent: 'space-between',
    padding: '16px 16px 18px 16px',
    background: 'white',
    borderTop: '2px solid',
    fontSize: pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
  }),
  ({ $isActive }) =>
    css({
      borderColor: $isActive ? 'lightGreen' : 'searchCategorySeparator',
      color: $isActive ? 'secondaryGreen' : 'gray500',
    }),
);
