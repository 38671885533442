import styled from '@emotion/styled';
import { css } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const ListItem = styled.div(
  css({
    marginBottom: [pxToRem(38), pxToRem(38), pxToRem(98)],
  }),
);

export const ListItemDivider = styled.div(
  css({
    border: '1px solid',
    borderColor: 'searchBorder',
    margin: '32px 0',
    display: ['block', 'none'],
  }),
);
