import React from 'react';
import getRangeString from '../../utils/getRangeString';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import { FiltersList, FiltersWrapper, FilterTitle } from './Filters.styled';

const Filters = () => {
  const { dateRangeState } = useGlobalContext();

  return (
    <FiltersWrapper>
      <p>Filters:</p>
      <FiltersList>
        <div>
          <FilterTitle>Date range:</FilterTitle>
          <p>
            {getRangeString(
              dateRangeState[0].startDate,
              dateRangeState[0].endDate,
              !!dateRangeState[0].startDate,
            )}
          </p>
        </div>
      </FiltersList>
    </FiltersWrapper>
  );
};

export default Filters;
