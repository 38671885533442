import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGlobalContext } from '../components/GlobalContextProvider/GlobalContextProvider';
import initDateRangeState from '../consts/initDateRangeState';
import { NOT_COVERED_TITLE_PERCENTS } from '../consts/style-consts';

const useAfterRouteUpdate = () => {
  const router = useRouter();
  const {
    isSearchActive,
    setIsSearchActive,
    setSearchValue,
    setPercentOfCoveredTitle,
    setDateRangeState,
    isNavActive,
    setIsNavActive,
    setIsSubcategoryFilterActive,
  } = useGlobalContext();

  useEffect(() => {
    if (isSearchActive) {
      setIsSearchActive(false);
    }

    if (isNavActive) {
      setIsNavActive(false);
    }

    if (router.asPath.includes('reports')) {
      setDateRangeState([initDateRangeState]);
    }

    setPercentOfCoveredTitle(NOT_COVERED_TITLE_PERCENTS);
    setSearchValue('');
    setIsSubcategoryFilterActive(true);
  }, [router.asPath]);
};

export default useAfterRouteUpdate;
