import React from 'react';
import BasePage from './BasePage';

const BasePageWrap = (Component) =>
  function basePageWrap(props) {
    return (
      <BasePage {...props} _class={Component.name}>
        <Component {...props} />
      </BasePage>
    );
  };

export default BasePageWrap;
