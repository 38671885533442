import { css } from 'theme-ui';
import styled from '../../lib/transient-styled';
import pxToRem from '../../utils/pixelsToRem';

const StyledName = styled.h1(
  css({
    position: 'relative',
    width: '162px',
    mt: '15px',
    mb: '9px',
    p: '5px 0',
    fontWeight: '600',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    cursor: 'pointer',

    '&:hover': {
      color: 'hoverLink',
      borderColor: 'hoverLink',
    },

    '::first-letter': {
      textTransform: 'uppercase',
    },
  }),
  ({ $isActive }) =>
    css({
      pl: $isActive && '18px',
      ml: $isActive && '-22px',
      color: $isActive ? 'greenDark' : 'text',
      borderLeft: $isActive && '4px solid',
    }),
);

export default StyledName;
