import DOMPurify from 'isomorphic-dompurify';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { imgTagRegEx } from '../../consts/regex';
import {
  absoluteToRelativeInternalLink,
  IS_NEXT_EXPORT,
} from '../../utils/export';
import StyledRawHtml from './RawHtml.styled';

const RawHtml = ({ html, linesLimit, sanitize, className, style }) => {
  const router = useRouter();
  const htmlRef = useRef(null);

  const cleanHtml = sanitize
    ? DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
    : html;

  const fixedImgPathHtml = cleanHtml.replace(imgTagRegEx, (match, src) =>
    match.replace(src, `https://${process.env.NEXT_PUBLIC_HOST_ENV}${src}`),
  );

  useEffect(() => {
    if (!htmlRef.current) {
      return;
    }

    if (IS_NEXT_EXPORT) {
      return;
    }

    setTimeout(() => {
      if (!htmlRef.current) {
        return;
      }

      const all = htmlRef.current.querySelectorAll('a[href^="/"]');
      for (let i = 0; i < all.length; i += 1) {
        all[i].onclick = (e) => {
          e.preventDefault();
          router.push(all[i].href);
        };
      }
    }, 0);
  }, [htmlRef.current]);

  useLayoutEffect(() => {
    if (!IS_NEXT_EXPORT) {
      return;
    }

    const handleClick = (e) => {
      if (e?.target?.tagName === 'A') {
        e.preventDefault();
        window.location.href = absoluteToRelativeInternalLink(e.target.href);
      }
    };

    htmlRef.current.addEventListener('click', handleClick);
    return () => {
      htmlRef.current.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div ref={htmlRef}>
      <StyledRawHtml
        $linesLimit={linesLimit}
        dangerouslySetInnerHTML={{ __html: fixedImgPathHtml }}
        className={className}
        style={style}
      />
    </div>
  );
};

RawHtml.propTypes = {
  sanitize: PropTypes.bool,
};

RawHtml.defaultProps = {
  sanitize: true,
};

export default RawHtml;
