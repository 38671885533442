import styled from '@emotion/styled';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { css } from 'theme-ui';

export const Button = styled.button(({ isDisplayed }) =>
  css({
    padding: '12px',
    borderRadius: '50px',
    background: 'darkGreen',
    position: 'fixed',
    bottom: '40px',
    right: '40px',
    cursor: 'pointer',
    zIndex: 2,

    display: isDisplayed ? 'block' : 'none',
  }),
);

export const Icon = styled(AiOutlineArrowUp)(
  css({
    width: '22px',
    height: '22px',
    color: 'white',
  }),
);
