import { css, Flex } from 'theme-ui';
import styled from '../../lib/transient-styled';

const SpinnerWrapper = styled(Flex)(
  css({
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  ({ $isPagination, $isFullHeight }) =>
    css({
      mt: $isPagination && '-72px',
      height: $isFullHeight ? '100%' : 'max-content',
    }),
);

export default SpinnerWrapper;
