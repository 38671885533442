import styled from '@emotion/styled';
import { css } from 'theme-ui';

const StickyWrapper = styled.div(
  css({
    top: [0, 0, '82px'],
    position: 'sticky',
    height: 'max-content',
  }),
);

export default StickyWrapper;
