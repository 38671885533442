import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import usePaginationOnScroll from '../../hooks/usePaginationOnScroll';
import { NotFound } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import AlignCenter from '../../shared-styled/typography/aligners';
import { matchUrl } from '../../utils/matchUrl';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import Loader from '../Loader/Loader';
import UniversalLink from '../UniversalLink/UniversalLink';
import {
  SearchListLink,
  Container,
  Wrapper,
  Label,
  SectionTitle,
} from './SearchList.styled';

const SearchList = ({
  items,
  isLoading,
  lastSearchValue,
  setOffset,
  isScrolledDown,
  setIsScrollDown,
}) => {
  const { setIsSearchActive } = useGlobalContext();
  const router = useRouter();

  const searchRouterPush = async (url) => {
    await router.push(url);
    setIsSearchActive(false);
  };

  const getSearchArticleTitle = (el) =>
    matchUrl(el.contentType, ['armedforcespage', 'weapondetailpage'])
      ? `${el.url.split('/').at(-3)} - ${el.title}`
      : el.title;

  const listRef = useRef();

  usePaginationOnScroll(listRef, setOffset, isScrolledDown, setIsScrollDown);

  return (
    <Wrapper>
      <Label>Search results:</Label>
      <Container ref={listRef}>
        {!items ? (
          <AlignCenter>
            <NotFound>Please press Enter to search</NotFound>
          </AlignCenter>
        ) : items.length ? (
          <>
            <SectionTitle>Records</SectionTitle>
            {items.map((el) => (
              <UniversalLink key={el.title} href={el.url || ''} passHref>
                <SearchListLink
                  onClick={() => searchRouterPush(el.url)}
                  key={el.title}
                >
                  {getSearchArticleTitle(el)}
                </SearchListLink>
              </UniversalLink>
            ))}
            {isLoading && <Loader isPagination={true} isSmall={true} />}
          </>
        ) : isLoading ? (
          <Loader isFullHeight={true} />
        ) : (
          <AlignCenter>
            <NotFound>No Results</NotFound>
            <p>
              {`Sorry, your search "${lastSearchValue}" did not match any result.`}
            </p>
            <p>Please try again.</p>
          </AlignCenter>
        )}
      </Container>
    </Wrapper>
  );
};

SearchList.propTypes = {};

SearchList.defaultProps = {};

export default SearchList;
