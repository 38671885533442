import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import { GreyParagraph } from '../../shared-styled/typography/parargraphs';
import pxToRem from '../../utils/pixelsToRem';

export const Container = styled(Flex)(
  css({
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-between',
    alignItems: ['center', 'normal'],
    padding: [
      '35px 22px',
      '35px 22px',
      '55px 4% 55px 80px',
      '55px 7% 55px 80px',
      '55px 18% 55px 80px',
    ],
    height: ['100%', '100%', '485px', '470px'],
    background: 'linear-gradient(115.51deg, #191E1D 13.83%, #123239 53.2%)',
    marginTop: 'auto',
  }),
);

export const LogoWrapper = styled(Flex)(
  css({
    flexDirection: 'column',
    alignItems: ['center', 'normal'],
  }),
);

export const InfoColumn = styled(Flex)(
  css({
    maxWidth: ['100%', '350px'],
    px: '12px',
    flexDirection: 'column',
    gap: ['34px', '40px', '42px', '48px', '64px'],
  }),
);

export const NavColumn = styled(Flex)(
  css({
    maxWidth: '745px',
    px: '12px',
    flexDirection: 'column',
    gap: ['24px', '40px'],
  }),
);

export const SmallDescription = styled.p(
  css({
    mt: 3,
    width: '200px',
    fontSize: pxToRem(9),
    lineHeight: pxToRem(12),
    color: 'white',
  }),
);

export const CopyrightBlock = styled(Flex)(
  css({
    flexDirection: ['column-reverse', 'column'],
    gap: ['24px', 0],
  }),
);

export const Copyright = styled(GreyParagraph)(
  css({
    textAlign: ['center', 'start'],
  }),
);
