import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { NotFound } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import AlignCenter from '../../shared-styled/typography/aligners';
import { matchUrl } from '../../utils/matchUrl';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import Loader from '../Loader/Loader';
import StaticImage from '../StaticImage';
import UniversalLink from '../UniversalLink/UniversalLink';
import {
  SearchListLink,
  Container,
  Wrapper,
  Label,
  SectionTitle,
  PaginationContainer,
  StepButton,
  PageButton,
  PageButtonsContainer,
  Placeholder,
} from './SearchList.styled';

const PAGINATION_STEP = 20;
const MAX_PAGES_DISPLAYED = 5;

const leftArrow = '/img/arrow-narrow-left.svg';
const rightArrow = '/img/arrow-narrow-right.svg';

const SearchListWithPagesPagination = ({
  items,
  lastSearchValue,
  isLoading,
  setOffset,
  offset,
  totalItems,
}) => {
  const { setIsSearchActive } = useGlobalContext();
  const router = useRouter();

  const searchRouterPush = async (url) => {
    await router.push(url);
    setIsSearchActive(false);
  };

  const getSearchArticleTitle = (el) =>
    matchUrl(el.contentType, ['armedforcespage', 'weapondetailpage'])
      ? `${el.url.split('/').at(-3)} - ${el.title}`
      : el.title;

  const listRef = useRef();
  const totalPages = Math.floor(totalItems / PAGINATION_STEP) + 1;
  const currentPage = Math.floor(offset / PAGINATION_STEP) + 1;

  const handlePrevious = () => {
    setOffset((offset) => offset - PAGINATION_STEP);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setOffset((offset) => offset + PAGINATION_STEP);
    }
  };

  const updateOffsetByPageNumber = (i) => {
    setOffset(() => (i - 1) * PAGINATION_STEP);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const halfDisplayed = Math.floor(MAX_PAGES_DISPLAYED / 2);

    let hasFirstEllipsis = false;
    let hasSecondEllipsis = false;

    for (let i = 1; i <= totalPages; i += 1) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - halfDisplayed && i <= currentPage + halfDisplayed)
      ) {
        pageNumbers.push(
          <PageButton
            key={i}
            onClick={() => updateOffsetByPageNumber(i)}
            $isActive={i === currentPage ? 'active' : ''}
          >
            {i}
          </PageButton>,
        );
      } else if (i === currentPage - halfDisplayed - 1 && !hasFirstEllipsis) {
        hasFirstEllipsis = true;
        pageNumbers.push(<span key={i}>...</span>);
      } else if (i === currentPage + halfDisplayed + 1 && !hasSecondEllipsis) {
        hasSecondEllipsis = true;
        pageNumbers.push(<span key={i}>...</span>);
      }
    }

    return pageNumbers;
  };

  return (
    <Wrapper>
      <Label>Search results:</Label>
      <Container ref={listRef}>
        {!items ? (
          <AlignCenter>
            <NotFound>Please press Enter to search</NotFound>
          </AlignCenter>
        ) : items.length ? (
          <>
            <SectionTitle>Records</SectionTitle>
            {items.map((el) => (
              <UniversalLink key={el.title} href={el.url || ''} passHref>
                <SearchListLink
                  onClick={() => searchRouterPush(el.url)}
                  key={el.title}
                >
                  {getSearchArticleTitle(el)}
                </SearchListLink>
              </UniversalLink>
            ))}
            {isLoading && <Loader isPagination={true} isSmall={true} />}
            {!isLoading && (
              <PaginationContainer>
                {currentPage > 1 ? (
                  <StepButton onClick={handlePrevious} $isLeft={true}>
                    <StaticImage
                      width={20}
                      height={20}
                      alt='leftArrow'
                      src={leftArrow}
                      priority
                    />
                    <span>Previous</span>{' '}
                  </StepButton>
                ) : (
                  <Placeholder />
                )}

                <PageButtonsContainer>
                  {renderPageNumbers()}
                </PageButtonsContainer>

                {currentPage < totalPages ? (
                  <StepButton onClick={handleNext}>
                    <span>Next</span>{' '}
                    <StaticImage
                      width={20}
                      height={20}
                      alt='rightArrow'
                      src={rightArrow}
                      priority
                    />
                  </StepButton>
                ) : (
                  <Placeholder />
                )}
              </PaginationContainer>
            )}
          </>
        ) : isLoading ? (
          <Loader isFullHeight={true} />
        ) : (
          <AlignCenter>
            <NotFound>No Results</NotFound>
            <p>
              {`Sorry, your search "${lastSearchValue}" did not match any result.`}
            </p>
            <p>Please try again.</p>
          </AlignCenter>
        )}
      </Container>
    </Wrapper>
  );
};

SearchListWithPagesPagination.propTypes = {};

SearchListWithPagesPagination.defaultProps = {};

export default SearchListWithPagesPagination;
