import React, { useRef } from 'react';
import { Grid } from 'theme-ui';
import CategoryName from '../../components/CategoryName/CategoryName';
import Filters from '../../components/Filters/Filters';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Loader from '../../components/Loader/Loader';
import PostPreview from '../../components/PostPreview';
import useSearchPaginationOnScroll from '../../hooks/useSearchPaginationOnScroll';
import { NotFound } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import {
  ListItem,
  ListItemDivider,
} from '../../shared-styled/blocks/listPagesBlocks';
import StickyWrapper from '../../shared-styled/layout/StickyWrapper';
import AlignCenter from '../../shared-styled/typography/aligners';

const NewsAndReportSearchPage = ({
  items,
  isLoading,
  setOffset,
  isScrolledDown,
  setIsScrollDown,
  className,
}) => {
  const { searchContentName } = useGlobalContext();
  const listRef = useRef();

  useSearchPaginationOnScroll(
    listRef,
    setOffset,
    isScrolledDown,
    setIsScrollDown,
  );

  return (
    <Grid
      className={className}
      columns={['4fr', '1fr 3fr 1fr', '1fr 2fr 1fr', '4fr 9fr 6fr']}
      p={['32px 20px 72px 20px', '70px 40px 100px 96px']}
    >
      <StickyWrapper>
        <CategoryName>{searchContentName}</CategoryName> <Filters />
      </StickyWrapper>
      <div ref={listRef}>
        {!items ? (
          <AlignCenter>
            <NotFound>Please press Enter to search</NotFound>
          </AlignCenter>
        ) : items?.length ? (
          <>
            {items.map((item) => (
              <ListItem key={item.slug}>
                <PostPreview {...item} href={item.url} />
                <ListItemDivider />
              </ListItem>
            ))}
            {isLoading && <Loader isPagination={true} isSmall={true} />}
          </>
        ) : isLoading ? (
          <Loader isFullHeight={true} />
        ) : (
          <NotFound>No Results Found</NotFound>
        )}
      </div>
    </Grid>
  );
};

export default NewsAndReportSearchPage;
