import styled from '@emotion/styled';
import { css } from 'theme-ui';
import { CategoryContainer } from '../../shared-styled/layout/CategoryContainer';

const SearchPageContainer = styled(CategoryContainer)(
  css({
    position: 'relative',
  }),
);

// export const SecretButton = styled('button')(
//   css({
//     position: 'absolute',
//     width: '150px',
//     height: '20px',
//     background: 'transparent',
//     border: 'none',
//     cursor: 'pointer',
//     opacity: 0,
//     transition: 'opacity 1.5s',
//     top: '300px',

//     '&:hover': {
//       opacity: 1,
//     },
//   }),
// );

export default SearchPageContainer;
