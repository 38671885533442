import { useRouter } from 'next/router';
import React from 'react';
import StyledName from './CategoryName.styled';

const CategoryName = ({ children, isActive }) => {
  const router = useRouter();

  // const goToRoot = () => {
  //   router.push(`${router.asPath.split('/')[1]}`);
  // };

  return <StyledName $isActive={isActive}>{children}</StyledName>;
};

CategoryName.defaultProps = {
  isActive: true,
};

export default CategoryName;
