import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';

import PropTypes from 'prop-types';
import React from 'react';

import { dayTextMonthDateFormat } from '../../consts/date-formats';
import { htmlTagRegEx } from '../../consts/regex';
import { PREVIEW_LENGTH } from '../../consts/style-consts';
import insertSpaceAfterPunctuation from '../../utils/insertSpaceAfterPunctuation';
import textTrimmer from '../../utils/textTrimmer';
import TagsList from '../TagsList/TagsList';
import UniversalLink from '../UniversalLink/UniversalLink';
import { Footer, StyledRawHtml, Title, StyledLink } from './PostPreview.styled';

dayjs.extend(utc);

const PostPreview = ({
  title,
  preview,
  authors,
  author,
  source,
  publishedDate,
  href,
  countryList,
}) => (
  <div>
    {countryList && <TagsList tagsList={countryList} />}
    <Title>
      <UniversalLink href={href}>
        <StyledLink>{title}</StyledLink>
      </UniversalLink>
    </Title>
    <StyledRawHtml
      html={
        preview &&
        textTrimmer(
          insertSpaceAfterPunctuation(
            preview.replace(htmlTagRegEx, ''),
            PREVIEW_LENGTH,
          ),
        )
      }
    />
    <Footer>
      <div>{authors || source || author}</div>
      <div>{dayjs.utc(publishedDate).format(dayTextMonthDateFormat)}</div>
    </Footer>
  </div>
);

PostPreview.defaultProps = {
  title: '',
  preview: '',
  authors: '',
  publishedDate: '',
  href: '',
};

PostPreview.propTypes = {
  title: PropTypes.string,
  preview: PropTypes.string,
  authors: PropTypes.string,
  publishedDate: PropTypes.string,
  href: PropTypes.string,
};

export default PostPreview;
