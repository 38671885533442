import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getRequest } from '../api/wagtail';
import { useGlobalContext } from '../components/GlobalContextProvider/GlobalContextProvider';
import { backendDateFormat } from '../consts/date-formats';
import { IS_NEXT_EXPORT } from '../utils/export';

const DELAY_TIME = 800;
const PREVENTIVE_LOADING_HEIGHT = 1800; // In pixels

function usePaginationOnScroll(listRef, next, items, setItems, staticItems) {
  const { setItemsLoading, dateRangeState } = useGlobalContext();
  const [nextItemsPath, setNextItemsPath] = useState(next);
  const [staticItemsOffset, setStaticItemsOffset] = useState(12);
  const [isScrolledDown, setIsScrollDown] = useState(false);

  const handleScroll = async () => {
    if (!listRef.current) return;

    if (
      document.defaultView?.scrollY >=
      listRef.current.offsetHeight - PREVENTIVE_LOADING_HEIGHT
    ) {
      setIsScrollDown(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isScrolledDown) return;
    if (!nextItemsPath) return;

    if (!dateRangeState[0].startDate && IS_NEXT_EXPORT) {
      setItemsLoading(true);

      const newItems = staticItems.slice(
        staticItemsOffset,
        staticItemsOffset + 12,
      );

      setItems([...items, ...newItems] || []);

      setStaticItemsOffset(staticItemsOffset + 12);
      setItemsLoading(false);
      setIsScrollDown(false);

      return;
    }

    const delayDebounce = setTimeout(async () => {
      setItemsLoading(true);

      const url = dateRangeState[0].startDate
        ? `${nextItemsPath}
          &from_date=${dayjs(dateRangeState[0].startDate).format(
            backendDateFormat,
          )}&to_date=${dayjs(dateRangeState[0].endDate).format(
            backendDateFormat,
          )}`
        : nextItemsPath;

      const { json: pageData } = await getRequest(url);
      const pageDataResults = pageData.componentProps.items.results || [];
      setItems([...items, ...pageDataResults] || []);

      setItemsLoading(false);
      setNextItemsPath(pageData.componentProps.items.next);
      setIsScrollDown(false);
    }, DELAY_TIME);

    return () => clearTimeout(delayDebounce);
  }, [isScrolledDown]);
}

export default usePaginationOnScroll;
