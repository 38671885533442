import React from 'react';
import { Tag, TagsContainer } from './TagsList.styled';

const TagsList = ({ tagsList }) => (
  <TagsContainer>
    {tagsList.map((el) => (
      <Tag key={el.name}>{el.name}</Tag>
    ))}
  </TagsContainer>
);

export default TagsList;
