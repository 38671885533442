import { useBreakpointIndex } from '@theme-ui/match-media';
import React from 'react';
import {
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { navigation } from '../../consts/navigation';
import { MOBILE_BREAKPOINT_INDEX } from '../../consts/style-consts';
import {
  StAccordion,
  StAccordionItem,
  StAccordionItemButton,
} from '../../shared-styled/blocks/AccordionBlocks';
import { GoldenAnchor } from '../../shared-styled/typography/Anchors';
import UniversalLink from '../UniversalLink/UniversalLink';
import {
  Navigation,
  NavGroup,
  NavGroupTitle,
  NavSeparator,
  MobileAnchor,
} from './FooterNavigation.styled';

const FooterNavigation = ({ navBlocks }) => {
  const index = useBreakpointIndex();

  return index <= MOBILE_BREAKPOINT_INDEX ? (
    <StAccordion allowZeroExpanded>
      {navBlocks.map((el) => (
        <StAccordionItem key={el.title}>
          <AccordionItemHeading>
            <StAccordionItemButton $uppercase>{el.title}</StAccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {el.items.map((el) => (
              <UniversalLink key={el.title} href={el.url}>
                <MobileAnchor>{el.title}</MobileAnchor>
              </UniversalLink>
            ))}
          </AccordionItemPanel>
        </StAccordionItem>
      ))}
    </StAccordion>
  ) : (
    <Navigation>
      {navBlocks.map((el) => (
        <NavGroup key={el.title}>
          <NavGroupTitle>{el.title}</NavGroupTitle>
          <NavSeparator />
          {el.items.map((el) => (
            <UniversalLink key={el.title} href={el.url}>
              <GoldenAnchor>{el.title}</GoldenAnchor>
            </UniversalLink>
          ))}
        </NavGroup>
      ))}
    </Navigation>
  );
};

FooterNavigation.defaultProps = {
  navBlocks: navigation,
};

export default FooterNavigation;
