import React, { useEffect, useState } from 'react';
import { Button, Icon } from './ToTopButton.styled';

const ToTopButton = ({ className }) => {
  const [isScrolledDown, _setIsScrolledDown] = useState(false);

  const setIsScrolledDown = () => {
    _setIsScrolledDown(window.scrollY > 30);
  };

  useEffect(() => {
    window.addEventListener('scroll', setIsScrolledDown);
    return () => window.removeEventListener('scroll', setIsScrolledDown);
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Button
      isDisplayed={isScrolledDown}
      onClick={goToTop}
      className={className}
    >
      <Icon />
    </Button>
  );
};

export default ToTopButton;
