const textTrimmer = (text, length) => {
  if (text.length <= length) return text;

  const trimmedText = text.substr(0, length);
  return `${trimmedText.substr(
    0,
    Math.min(trimmedText.length, trimmedText.lastIndexOf(' ')),
  )} ...`;
};

export default textTrimmer;
