import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const TagsContainer = styled(Flex)(
  css({
    gap: '10px',
  }),
);

export const Tag = styled.div(
  css({
    px: '15px',
    py: '5px',
    background: 'countryTag',
    fontSize: pxToRem(14),
    letterSpacing: '0.08em',
    color: 'white',
    cursor: 'default',

    '&:hover': {
      background: 'countryTagHover',
    },
  }),
);
