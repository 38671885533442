import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import { ClassicAnchor } from '../../shared-styled/typography/Anchors';
import pxToRem from '../../utils/pixelsToRem';

export const MobileAnchor = styled(ClassicAnchor)(
  css({
    display: 'block',
    fontSize: pxToRem(16),
    mb: '16px',

    '&:hover': {
      color: 'focus',
    },
  }),
);

export const Navigation = styled.nav(
  css({
    display: 'flex',
    gap: '64px',
    flexDirection: ['column', 'row'],
  }),
);

export const NavGroup = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '20px',
  }),
);

export const NavGroupTitle = styled.h4(
  css({
    fontWeight: '800',
    fontSize: pxToRem(18),
    lineHeight: '136%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: 'focus',
  }),
);

export const NavSeparator = styled.p(
  css({
    width: '50px',
    borderBottom: '1px solid white',
  }),
);
