import styled from '@emotion/styled';
import { css } from 'theme-ui';

export const ClassicParagraph = styled.p(
  css({
    fontSize: '0.875rem',
    lineHeight: '135%',
  }),
);

export const WhiteParagraph = styled(ClassicParagraph)(
  css({
    color: 'white',
    letterSpacing: '0.035em',
  }),
);

export const GreyParagraph = styled(ClassicParagraph)(
  css({
    color: 'classyGrey',
    letterSpacing: '0.05em',
    lineHeight: '175%',
  }),
);
