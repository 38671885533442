import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const FiltersWrapper = styled.div(
  css({
    mt: '25px',
    fontSize: pxToRem(14),
  }),
);

export const FiltersList = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '24px',
    py: '15px',
    pl: '23px',
  }),
);

export const FilterTitle = styled.p(
  css({
    mb: '8px',
    fontSize: pxToRem(12),
    color: 'classyGrey',
  }),
);
